@font-face {
  font-family: 'icomoon';
  src:  url('icomoon.eot?p1osy1');
  src:  url('icomoon.eot?p1osy1#iefix') format('embedded-opentype'),
    url('icomoon.ttf?p1osy1') format('truetype'),
    url('icomoon.woff?p1osy1') format('woff'),
    url('icomoon.svg?p1osy1#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-SVG_myWebpage:before {
  content: "\e917";
}
.icon-SVG_github_repo:before {
  content: "\e918";
}
.icon-SVG_Envelope_tight:before {
  content: "\e919";
}
.icon-mobile-phone:before {
  content: "\e916";
}
.icon-moon:before {
  content: "\e914";
}
.icon-SVG_barGraph:before {
  content: "\e912";
}
.icon-SVG_circleGraph:before {
  content: "\e913";
}
.icon-SVG_happy_client:before {
  content: "\e90e";
}
.icon-SVG_analysis:before {
  content: "\e910";
}
.icon-SVG_myBrand:before {
  content: "\e911";
}
.icon-SVG_menu_burger:before {
  content: "\e90c";
}
.icon-SVG_completed:before {
  content: "\e90b";
}
.icon-SVG_Graduated:before {
  content: "\e90a";
}
.icon-SVG_Award:before {
  content: "\e900";
}
.icon-SVG_award_clearStar:before {
  content: "\e901";
}
.icon-SVG_briefcase:before {
  content: "\e902";
}
.icon-SVG_Envelope:before {
  content: "\e903";
}
.icon-SVG_Github:before {
  content: "\e904";
}
.icon-SVG_Home:before {
  content: "\e905";
}
.icon-SVG_Linkedin:before {
  content: "\e906";
}
.icon-SVG_PaperPlane:before {
  content: "\e907";
}
.icon-SVG_SustainableIdea_1:before {
  content: "\e908";
}
.icon-SVG_Whatsapp:before {
  content: "\e909";
}
.icon-pencil2:before {
  content: "\e915";
}
.icon-image:before {
  content: "\e90d";
}
.icon-camera:before {
  content: "\e90f";
}
.icon-file-picture:before {
  content: "\e927";
}
.icon-address-book:before {
  content: "\e944";
}
.icon-display:before {
  content: "\e956";
}
.icon-user-tie:before {
  content: "\e976";
}
.icon-spinner:before {
  content: "\e97a";
}
.icon-spinner2:before {
  content: "\e97b";
}
.icon-spinner3:before {
  content: "\e97c";
}
.icon-spinner4:before {
  content: "\e97d";
}
.icon-spinner5:before {
  content: "\e97e";
}
.icon-spinner6:before {
  content: "\e97f";
}
.icon-spinner7:before {
  content: "\e980";
}
.icon-spinner8:before {
  content: "\e981";
}
.icon-spinner9:before {
  content: "\e982";
}
.icon-spinner10:before {
  content: "\e983";
}
.icon-search:before {
  content: "\e986";
}
.icon-wrench:before {
  content: "\e991";
}
.icon-equalizer:before {
  content: "\e992";
}
.icon-equalizer2:before {
  content: "\e993";
}
.icon-hammer:before {
  content: "\e996";
}
.icon-stats-bars2:before {
  content: "\e99d";
}
.icon-leaf:before {
  content: "\e9a4";
}
.icon-rocket:before {
  content: "\e9a5";
}
.icon-fire:before {
  content: "\e9a9";
}
.icon-lab:before {
  content: "\e9aa";
}
.icon-bin:before {
  content: "\e9ac";
}
.icon-bin2:before {
  content: "\e9ad";
}
.icon-accessibility:before {
  content: "\e9b2";
}
.icon-power:before {
  content: "\e9b5";
}
.icon-switch:before {
  content: "\e9b6";
}
.icon-menu:before {
  content: "\e9bd";
}
.icon-sphere:before {
  content: "\e9c9";
}
.icon-eye:before {
  content: "\e9ce";
}
.icon-sun:before {
  content: "\e9d4";
}
.icon-star-empty:before {
  content: "\e9d7";
}
.icon-grin:before {
  content: "\e9e9";
}
.icon-plus:before {
  content: "\ea0a";
}
.icon-minus:before {
  content: "\ea0b";
}
.icon-cross:before {
  content: "\ea0f";
}
.icon-checkmark:before {
  content: "\ea10";
}
.icon-checkmark2:before {
  content: "\ea11";
}
.icon-play2:before {
  content: "\ea15";
}
.icon-pause:before {
  content: "\ea16";
}
.icon-stop:before {
  content: "\ea17";
}
.icon-arrow-up-left:before {
  content: "\ea31";
}
.icon-arrow-up:before {
  content: "\ea32";
}
.icon-arrow-up-right:before {
  content: "\ea33";
}
.icon-arrow-right:before {
  content: "\ea34";
}
.icon-arrow-down-right:before {
  content: "\ea35";
}
.icon-arrow-down:before {
  content: "\ea36";
}
.icon-arrow-down-left:before {
  content: "\ea37";
}
.icon-arrow-left:before {
  content: "\ea38";
}
.icon-arrow-up-left2:before {
  content: "\ea39";
}
.icon-arrow-up2:before {
  content: "\ea3a";
}
.icon-arrow-up-right2:before {
  content: "\ea3b";
}
.icon-arrow-right2:before {
  content: "\ea3c";
}
.icon-arrow-down-right2:before {
  content: "\ea3d";
}
.icon-arrow-down2:before {
  content: "\ea3e";
}
.icon-arrow-down-left2:before {
  content: "\ea3f";
}
.icon-arrow-left2:before {
  content: "\ea40";
}
.icon-circle-up:before {
  content: "\ea41";
}
.icon-circle-right:before {
  content: "\ea42";
}
.icon-circle-down:before {
  content: "\ea43";
}
.icon-circle-left:before {
  content: "\ea44";
}
.icon-shift:before {
  content: "\ea4f";
}
.icon-checkbox-checked:before {
  content: "\ea52";
}
.icon-checkbox-unchecked:before {
  content: "\ea53";
}
.icon-radio-unchecked:before {
  content: "\ea56";
}
.icon-omega:before {
  content: "\ea66";
}
.icon-sigma:before {
  content: "\ea67";
}
.icon-github:before {
  content: "\eab0";
}
.icon-html-five2:before {
  content: "\eae5";
}
.icon-css3:before {
  content: "\eae6";
}
