
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("icomoon/iconfonts.css");


:root {
  --bg: #f3f0f0;
  --brand-color: #ff6f61;
  --header-color: #1d314c;
  --body-color: #443d3d;
  --link-teal: #2e747a;
  --link-teal-hover: #61a3ff;;
  --code-bg: #e5dfde;
  --code-color: #2e747a;
  --royal-blue: #61a3ff;
  --button-hover: #e5dfde;
}

/* 
 * ================================================================================ 
 *  Page configuration
 * ================================================================================ */

body{
  display: flex;
  justify-content: center;
}

h1{
  text-shadow: -4px 5px 10px gray;
}

a {
  text-decoration: none;
  background-repeat: repeat-x;
  background-position: right bottom -0.8px;
  background-size: 25% 25%;
  color: inherit;
  position: relative;
}

a:before {
  content: "";
  display: block;
  width: 100%;
  height: 0.5rem;
  background-color: var(--royal-blue);
  position: absolute;
  left: 0;
  bottom: -0.5rem;
  transform-origin: left;
  transform: scale(0);
  transition: transform 0.2s linear;
}

a:hover:before,
a:focus:before {
  transform: scale(1);
  transform: translateY(0.1rem); 
}

.tags-list {
  list-style-type: none;
  padding: 0;
  margin-top: 1rem;
  color: var(--brand-color);
  font-family: 'Inconsolata', monospace;
}

.tag {
  display: inline-block;
  padding: 0.5rem 1rem;
  margin-right: 1rem;
  font-size: 1.2rem;
  font-weight: 600;
}

.tag:before {
  content: "#";
}

.contact-icons{
  margin: 0 0.75rem;
}


.contact-links:hover:before,
.contact-links:focus:before{
  transform: scale(0) translateY(0);
}

.contact-icons{
  transition: all 0.5s ease-in-out; 
}

.contact-icons:hover,
.contact-icons:focus{
  color: var(--royal-blue);
  transform: scale(1.7) translateY(-20%);
}

.portfolio-cards{
  box-shadow: 0px 0px 20px 5px #828282;
}

.portrait{
  text-shadow: 2px 2px 2px #CE5937;
}

.navigator a:hover,
.navigator a:focus{
  background-color: var(--royal-blue);
  color: white;
  font-weight: 800;
}

.logo a:hover:before,
.logo a:focus:before,
.portrait a:hover:before,
.portrait a:focus:before,
.navigator a:hover:before,
.navigator a:focus:before {
  transform: scale(0);
  transform: translateY(-1); 
}

.quote-animate-fading {
  animation: fading 10s infinite;
}

@keyframes fading {
  0% {
    opacity: 0;
  }
  15% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  85% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* --------------------------------------------------------------- 
 * That's all there is.
 * --------------------------------------------------------------- */